import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import memoize from 'memoize-one';
import { nsOptions } from '../i18n';
import {
  elementsActions, inclusionsActions, projectElementsActions, projectEntriesActions,
} from '../redux/actions';
import {
  POOL_PROJECT_MUTEXES, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_MODULE, TARGET_TYPE_ELEMENT,
  ELEMENT_NOT_IDENTIFIER,
  ELEMENT_DIRECT_IDENTIFIER,
  ELEMENTS_TO_FILL_TYPES,
  ELEMENT_TYPE_DIVIDER,
  ELEMENT_TYPE_SPACER,
  ELEMENT_TYPE_TITLE,
} from '../constants';
import ElementLinksInfo from './ElementLinksInfo';
import ElementOverMenu from './ElementOverMenu';
import ElementComment from './ElementComment';
import ElementContentEditable from './ElementContentEditable';
import NewTooltip from './NewTooltip';
import ElementFieldControls from './ElementFieldControls';
import AuditTrailModal from './AuditTrailModal';
import { MiniLoader } from './Loader';
import DropdownMenu from './NewDropdownMenu';
import QualifyMissingData from './QualifyMissingData';
import { isBrowserView } from './BrowserView';
import { consistencyCheckActive, getConsistencySettings } from '../utils/consistency-check';
import { childrenPropTypes } from '../utils/generic-prop-types';
import ElementUtil from '../utils/ElementUtil';
import ErrorUtil from '../utils/ErrorUtil';
import TextUtil from '../utils/TextUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { getPersistentMutex } from '../utils/persistent-mutex';
import withDataDisclosureMsgModal from './withDataDisclosureMsgModal';
import FAQLink from './FAQLink';
import { checkLinks } from '../utils/links';
import IdentifierIcon from './IdentifierIcon';
import fromReduxState from '../utils/redux';
import { extractEmptyProjectElements } from '../utils/inclusion';

export const ENTRY_LATENCY_TIMEOUT_MS = 500;
export const DISPLAYED_TIME_FOR_CALCULATION_LINKS = 2000;

const VALID = 'valid';
const INVALID = 'INVALID';
const MISSING = 'missing';

export const retrieveProjectEntries = (projectEntries, inclusionId, projectElementId,
  moduleInstanceId) => {
  if (inclusionId) {
    const res = projectEntries.filter((pEntry) => (
      pEntry.inclusion === inclusionId
          && pEntry.project_element === projectElementId
          && pEntry.module === moduleInstanceId));
    return res;
  }
  return null;
};

export const retrieveProjectEntry = (projectEntries, inclusionId, projectElementId,
  moduleInstanceId) => {
  const res = retrieveProjectEntries(projectEntries, inclusionId, projectElementId,
    moduleInstanceId);
  if (res && res.length >= 1) return res[0];
  return null;
};

export const getMutex = (props) => (
  props.isEntryMode ? getPersistentMutex(POOL_PROJECT_MUTEXES, [
    props.projectElementId,
    props.inclusionId,
    props.moduleInstanceId,
  ]) : undefined
);

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  elements: state.elements,
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: ownProps.projectElementId ? state.projectElements[ownProps.projectElementId]
    : null,
  elementLinks: state.elementLinks,
  projectElements: state.projectElements,
  projectEntries: state.projectEntries,
  pages: state.projectPages,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (id, data) => dispatch(elementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  patchProjectElement: async (id, data) => dispatch(projectElementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  readInclusion: async (params) => dispatch(inclusionsActions.read(ownProps.inclusion.id, params)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
@withDataDisclosureMsgModal(true)
class ElementBase extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementCategory: PropTypes.oneOf(['static', 'editable', 'entry']).isRequired,
    hidden: PropTypes.bool,
    ready: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isAnonymized: PropTypes.bool,
    noComment: PropTypes.bool,
    siblingType: PropTypes.shape(),
    noLabel: PropTypes.bool,
    elementId: PropTypes.number.isRequired,
    projectElementId: PropTypes.number,
    parent: PropTypes.shape(),
    addValue: PropTypes.func,
    methods: PropTypes.shape(),
    className: PropTypes.string,
    subClassName: PropTypes.string,
    centerContent: PropTypes.bool,
    admin: PropTypes.bool,
    formSortingHandlerId: PropTypes.string,
    moduleInstanceId: PropTypes.number,
    children: childrenPropTypes().isRequired,
    element: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape().isRequired,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    projectElements: PropTypes.shape().isRequired,
    readInclusion: PropTypes.func.isRequired,
    rootProjectElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    projectEntries: PropTypes.shape().isRequired,
    patchElement: PropTypes.func.isRequired,
    patchProjectElement: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
    pages: PropTypes.shape().isRequired,
    warningMsg: childrenPropTypes(),
    warningIconColor: PropTypes.string,
  };

  static defaultProps = {
    hidden: false,
    ready: false,
    isEntryMode: false,
    isEditMode: false,
    isReadOnly: false,
    isAnonymized: false,
    noComment: false,
    siblingType: null,
    noLabel: false,
    projectElementId: null,
    parent: null,
    addValue: null,
    methods: null,
    className: '',
    subClassName: '',
    centerContent: false,
    admin: false,
    formSortingHandlerId: '',
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    warningMsg: undefined,
    warningIconColor: 'text-yellow',
  };

  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      ready: false,
      missingHighLighted: false,
      missingData: false,
      inconsistentHighLighted: false,
      qualifiedMissingHighLighted: false,
      loading: false,
      displayCalculationLinks: false,
    };
    this.timeoutHandler = new TimeoutHandler();
    this.memoizedCheckLinks = memoize((
      elements, elementLinks, projectElements, rootProjectElements, projectEntries, pages,
      projectElement, inclusionId, moduleInstanceId,
    ) => checkLinks(
      elements, elementLinks, projectElements, rootProjectElements, projectEntries, pages,
      projectElement, TARGET_TYPE_ELEMENT, inclusionId, moduleInstanceId,
    ));
    this.toElLinksArray = fromReduxState((elementLinks) => Object.values(elementLinks));
    this.toPElementsArray = fromReduxState((projectElements) => Object.values(projectElements));
    this.toPEntriesArray = fromReduxState((projectEntries) => Object.values(projectEntries));
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    if (this.props.isEntryMode && !this.props.isEditMode && !this.props.admin) {
      await this.initEntryMode();
    } else {
      await this.initEditMode();
    }
  };

  initEditMode = async () => new Promise(
    (resolve) => this.setState({ ready: true }, resolve),
  );

  initEntryMode = async () => {
    const {
      elements, elementLinks: elLinks, projectElements: pEls, projectElement, inclusion,
      moduleInstanceId, projectEntries: pEntries, rootProjectElements, pages,
    } = this.props;
    const elementLinks = this.toElLinksArray(elLinks);
    const projectElements = this.toElLinksArray(pEls);
    const projectEntries = this.toPEntriesArray(pEntries);
    try {
      // Links
      const linkResult = await this.memoizedCheckLinks(elements, elementLinks, projectElements,
        rootProjectElements, projectEntries, pages, projectElement, inclusion.id, moduleInstanceId);
      this.setState({ hidden: !linkResult, ready: true });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      this.setState({ ready: false });
    }
  };

  checkLinks = async () => {
    const {
      elements, elementLinks: elLinks, projectElements: pEls, projectEntries: pEntries,
      projectElement, inclusion, moduleInstanceId, rootProjectElements, pages,
    } = this.props;
    const elementLinks = this.toElLinksArray(elLinks);
    const projectElements = this.toElLinksArray(pEls);
    const projectEntries = this.toPEntriesArray(pEntries);
    const linkResult = await this.memoizedCheckLinks(elements, elementLinks, projectElements,
      rootProjectElements, projectEntries, pages, projectElement, inclusion.id, moduleInstanceId);
    if (!linkResult !== this.state.hidden && linkResult) {
      setTimeout(() => {
        Toast.info(this.props, 'inclusion:new-element-displayed');
      }, DISPLAYED_TIME_FOR_CALCULATION_LINKS);
    }
    this.setState({ hidden: !linkResult });
  };

  isParentElement = () => {
    const parent = Object.values(this.props.elementLinks).findIndex((link) => (
      link.source === this.props.projectElement.id
    )) >= 0;
    return parent;
  }

  patchElement = async (patch) => {
    const { patchElement, element } = this.props;
    return patchElement(element.id, patch);
  };

  patchProjectElement = async (patch) => {
    const { patchProjectElement, projectElement } = this.props;
    return patchProjectElement(projectElement.id, patch);
  };

  updateElementName = (e) => {
    const text = e.target.value;
    this.timeoutHandler.doAfterTimeout(async () => {
      const { patchElement, element } = this.props;
      try {
        await patchElement(element.id, { name: text });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    });
  };

  checkForCorruptedMissingDataCount = async () => {
    try {
      const {
        elements, elementLinks, projectElements, projectEntries, pages, rootProjectElements,
        inclusion, readInclusion,
      } = this.props;
      const emptyPEls = extractEmptyProjectElements(
        Object.values(elements).filter((el) => (
          ELEMENTS_TO_FILL_TYPES.includes(el.type)
        )).map((el) => (
          el.id
        )),
        this.toPEntriesArray(projectEntries),
        pages,
        this.toElLinksArray(projectElements),
        rootProjectElements,
        elements,
        this.toElLinksArray(elementLinks),
        inclusion,
      );
      const missingDataCount = Object.values(emptyPEls).reduce((accumulator, page) => (
        accumulator + Object.values(page).length
      ), 0);
      if (missingDataCount !== inclusion.missing_data_count) {
        // Frontend and backend missing data evaluation mismatch: send a request to the backend
        // to do a full recount of the inclusion missing data.
        await readInclusion({ force_missing_data_full_recount: true });
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  highlightElement = (inconsistentData = false, isQualifiedMissingData = false) => {
    const {
      missingHighLighted, missingData, inconsistentHighLighted,
      qualifiedMissingHighLighted,
    } = this.state;
    if (!missingHighLighted && !missingData && !inconsistentHighLighted
      && !qualifiedMissingHighLighted) {
      // Highlight in yellow -> missing data
      if (!inconsistentData && !isQualifiedMissingData) {
        this.setState({ missingHighLighted: true });
        clearTimeout(this.highLightDelay);
        this.highLightDelay = setTimeout(() => {
          this.setState({ missingHighLighted: false });
        }, 2502);
      } else if (inconsistentData) {
        // Highlight in red -> inconsistent data
        this.setState({ inconsistentHighLighted: true });
        clearTimeout(this.highLightDelay);
        this.highLightDelay = setTimeout(() => {
          this.setState({ inconsistentHighLighted: false });
        }, 2502);
      } else {
        // Highlight in blue -> qualified missing data
        this.setState({ qualifiedMissingHighLighted: true });
        clearTimeout(this.highLightDelay);
        this.highLightDelay = setTimeout(() => {
          this.setState({ qualifiedMissingHighLighted: false });
        }, 2502);
      }
    }
  }

  toggleMissingDataClass = () => {
    const { missingData } = this.state;
    this.setState({ missingData: !missingData });
  }

  focusOnNameInput = () => {
    const nameInput = this.contentEditable;
    if (nameInput && nameInput.props && nameInput.props.resizeInput) {
      this.contentEditable.switchToInput();
    }
  }

  showLoader = (show = true) => {
    this.setState({ loading: show });

    if (this.isParentElement() && !show) {
      this.setState({ displayCalculationLinks: true });
      setTimeout(() => {
        this.setState({ displayCalculationLinks: false });
      }, DISPLAYED_TIME_FOR_CALCULATION_LINKS);
    }
  }

  dequalifyMissingData = async () => {
    const {
      projectEntries: pEnts, isEntryMode, inclusion, projectElement, moduleInstanceId,
      removeEntry,
    } = this.props;
    const projectEntries = this.toPEntriesArray(pEnts);
    const mutex = getMutex(this.props);
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        this.setState({ loading: true });
        const pEntries = isEntryMode ? retrieveProjectEntries(
          projectEntries,
          inclusion.id,
          projectElement.id,
          moduleInstanceId,
        ) : null;
        const pEntryMissing = pEntries.find((projectEntry) => projectEntry.missing);
        if (pEntryMissing) {
          await removeEntry(pEntryMissing.id);
          this.checkForCorruptedMissingDataCount();
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const {
      elementCategory, isEditMode, noComment, noLabel, addValue, methods, className, centerContent,
      children, t, parent, element, projectElement, formSortingHandlerId, subClassName,
      isAnonymized, isReadOnly, siblingType, isEntryMode, projectEntries: pEnts, inclusion,
      inclusionId, moduleInstanceId, pages, projectElements: pEls, elements, warningMsg,
      warningIconColor,
    } = this.props;
    const { template } = element;
    const {
      hidden, ready, missingHighLighted, missingData, inconsistentHighLighted,
      qualifiedMissingHighLighted, loading, displayCalculationLinks,
    } = this.state;
    const projectElements = this.toElLinksArray(pEls);
    const projectEntries = this.toPEntriesArray(pEnts);
    const elementName = ElementUtil.formatElementName(element, t);
    const optional = projectElement && !projectElement.required;

    const pEntries = isEntryMode ? retrieveProjectEntries(
      projectEntries,
      inclusionId,
      projectElement.id,
      moduleInstanceId,
    ) : null;

    const fieldControlActive = consistencyCheckActive(element);
    let consistencySettings = {};
    const pEntry = isEntryMode && pEntries && pEntries.length >= 1 ? pEntries[0] : null;

    if (fieldControlActive) {
      consistencySettings = getConsistencySettings(
        element, projectElements, elements, pages, t, inclusion,
      );
    }

    let entryStatus = null;
    let pEntryMissing = null;
    let disableQualifyMissingData = false;
    if (isEntryMode && pEntries) {
      pEntryMissing = pEntries.find((projectEntry) => projectEntry.missing);
      disableQualifyMissingData = isReadOnly
        || Boolean(pEntries.filter((projectEntry) => projectEntry.value !== undefined).length)
        || !isEntryMode;
      if (pEntryMissing) {
        entryStatus = MISSING;
      } else if (pEntry && pEntry.consistent === false) {
        entryStatus = INVALID;
      } else if (pEntry && pEntry.consistent && element.type !== ELEMENT_TYPE_MODULE
        && elementCategory === 'entry') {
        entryStatus = VALID;
      }
    }

    const showConsistencyCheckIcon = fieldControlActive
      && (entryStatus === INVALID || !isEntryMode);

    const showQualifyMissingData = elementCategory === 'entry'
      && element.type !== ELEMENT_TYPE_MODULE && !isReadOnly;

    const showAuditTrail = element.type !== ELEMENT_TYPE_CALCULATION
      && (inclusion && !inclusion.is_test);

    const showLinksInfo = element.type !== ELEMENT_TYPE_DIVIDER
     && element.type !== ELEMENT_TYPE_SPACER
     && element.type !== ELEMENT_TYPE_TITLE;

    let animationClassName = '';
    if (missingHighLighted) {
      animationClassName = 'highlighted-missing-data';
    } else if (missingData) {
      animationClassName = `${animationClassName} missing-data`;
    } else if (inconsistentHighLighted) {
      animationClassName = `${animationClassName} highlighted-inconsistent-data`;
    } else if (qualifiedMissingHighLighted) {
      animationClassName = `${animationClassName} highlighted-qualified-missing-data`;
    }

    return ready ? (
      <div
        className={`card border-0 element-row${hidden ? ' d-none' : ''}`}
      >
        <div
          className={`mb-1 card-body element-card${isEditMode
            ? ' element-editable'
            : ''}${className ? ` ${className}` : ''} ${animationClassName}`}
          id={`element-base-${projectElement.id}`}
        >
          {
            parent && (!isEntryMode || showLinksInfo) ? (
              <span className="element-in-margin">
                <ElementLinksInfo
                  {...this.props}
                  target={projectElement}
                  targetType={TARGET_TYPE_ELEMENT}
                />
              </span>
            ) : null
          }
          <div className="row align-items-center mr-0">
            <div className="col">
              <div className={`element-sub-card row${centerContent ? ' justify-content-center'
                : ''}${subClassName ? ` ${subClassName}` : ''}`}
              >
                {
                  isEditMode ? (
                    <ElementOverMenu
                      {...this.props}
                      element={element}
                      projectElement={projectElement}
                      elementName={elementName}
                      noComment={noComment}
                      noOptional={elementCategory === 'static' || elementCategory === 'editable'}
                      addValue={addValue}
                      formSortingHandlerId={formSortingHandlerId}
                      supportLinks
                      siblingType={siblingType}
                      methods={{
                        patchElement: this.patchElement,
                        patchProjectElement: this.patchProjectElement,
                        ...methods,
                      }}
                    />
                  ) : null
                }
                { noLabel ? null : (
                  <div className="col-12 mr-4 mb-2">
                    <h5 className="card-title d-inline-block text-dark w-100">
                      {
                        isEditMode ? (
                          <ElementContentEditable
                            containerId={`element-${element.id}`}
                            defaultValue={element.name}
                            disabled={Boolean(template)}
                            className={`element-edit-input label-input mr-4${optional ? ' element-optional' : ''}`}
                            staticLabelClassName={`element-edit-span label-input mr-3${optional ? ' element-optional' : ''}${template ? ' predef-var' : ''}`}
                            onChange={this.updateElementName}
                            placeholder={ElementUtil.getElementPlaceholder(element, t)}
                            autoTooltip
                            resizeInput
                            ref={(ref) => { this.contentEditable = ref; }}
                          />
                        ) : (
                          <span
                            readOnly={isReadOnly}
                            className={`mr-3 ${optional ? 'element-optional' : ''}`}
                            dangerouslySetInnerHTML={{ __html: TextUtil.escape(elementName) }}
                          />
                        )
                      }
                      {showConsistencyCheckIcon && (
                        <ElementFieldControls
                          {...this.props}
                          controls={consistencySettings}
                        />
                      )}
                      {element.identification_level !== ELEMENT_NOT_IDENTIFIER && (
                        <NewTooltip
                          content={(
                            <>
                              <div>
                                { isEntryMode && t('inclusion:form.identifier')}
                                { !isEntryMode && template && t('project:form.identif-predef') }
                                { !isEntryMode && !template && t(`project:form.${element.identification_level !== ELEMENT_DIRECT_IDENTIFIER ? 'identifier-indirect' : 'identifier-direct'}-tooltip`)}
                              </div>
                              <div>
                                <small>
                                  <i>
                                    { isEntryMode && t('inclusion:form.identifier-tooltip')}
                                    { !isEntryMode && t(`project:form.${template ? 'identif-predef' : 'identifier'}-tooltip-help`)}
                                  </i>
                                </small>
                              </div>
                            </>
                          )}
                          className="ml-2"
                        >
                          <IdentifierIcon
                            identificationLevel={element.identification_level}
                            className="identifier-icon"
                          />
                        </NewTooltip>
                      )}
                      {element.comment && (
                        <ElementComment
                          comment={element.comment}
                          className="p-0"
                        />
                      )}
                      {
                        isEntryMode && showAuditTrail && (
                          <NewTooltip
                            content={(
                              <span>
                                {t('project:audit-trail.icon-tooltip.variable')}
                                <FAQLink
                                  linkClassName="ml-2"
                                  onlyIcon
                                  articleId={10}
                                />
                              </span>
                            )}
                            interactive
                          >
                            <span>
                              <AuditTrailModal
                                {...this.props}
                                title={`${elementName} - ${t('project:audit-trail.title')}`}
                                showModifications
                                projectId={Object.values(pages)[0].project}
                                projectElement={projectElement}
                                moduleInstanceId={moduleInstanceId}
                                inclusion={inclusion}
                                size="md"
                              >
                                <button
                                  type="button"
                                  className="no-button-style pl-0"
                                >
                                  <FontAwesomeIcon
                                    icon={['far', 'history']}
                                    className="audit-trail-icon"
                                  />
                                </button>
                              </AuditTrailModal>
                            </span>
                          </NewTooltip>
                        )
                      }
                      {optional && (
                        <div className="element-optional-text">
                          { `(${t('common:form.optional')})` }
                        </div>
                      )}
                    </h5>
                  </div>
                )}
                {isAnonymized ? (
                  <div className="col">
                    {t('common:elements.anonymized')}
                  </div>
                ) : children}
                {warningMsg && (
                  <div className="col-12 mt-2 element-warning">
                    <FontAwesomeIcon
                      icon={['fas', 'exclamation-triangle']}
                      transform="grow-2"
                      className={`mr-2 ${isReadOnly ? '' : warningIconColor}`}
                    />
                    {warningMsg}
                  </div>
                )}
                {loading || displayCalculationLinks ? (
                  <div className="col-12 pt-1">
                    <span
                      className={`small font-italic text-newblue-2-light ${loading ? '' : 'd-none'}`}
                    >
                      {t('inclusion:saving')}
                    </span>
                    <MiniLoader className={`${loading ? '' : 'd-none'}`} />
                    {displayCalculationLinks && (
                    <>
                      <span
                        className="small font-italic text-newblue-2-light"
                      >
                        {t('inclusion:calcul-links-element')}
                      </span>
                      <MiniLoader />
                    </>
                    )}
                  </div>
                ) : (
                  isEntryMode && (
                    <div className="col-12 d-flex align-items-center pt-1">
                      {entryStatus !== null ? (
                        <>
                          {fieldControlActive && entryStatus === INVALID && (
                            <div className="small font-italic text-red pr-2">
                              {t('inclusion:invalid-entry')}
                            </div>
                          )}
                          {entryStatus === MISSING && (
                            <>
                              <div className="small font-italic text-newblue-1 pr-2">
                                {t(`inclusion:qualified-missing-data.declared-${pEntryMissing.missing.replace('_', '-')}`)}
                              </div>
                              {!isReadOnly && (
                                <button
                                  className="btn btn-link btn-dequalify-missing-data text-gray-dark p-0 d-flex align-items-center"
                                  onClick={() => this.dequalifyMissingData()}
                                >
                                  <span className="small font-italic">
                                    {t('common:button.cancel')}
                                  </span>
                                </button>
                              )}
                            </>
                          )}
                          {entryStatus === VALID && (
                            <div className="row align-items-center small font-italic text-newturquoise-2 pr-2">
                              <div className="col-auto pr-0">
                                <span className="align-middle">
                                  {t('inclusion:valid-entry')}
                                </span>
                              </div>
                              <div className="col-auto px-0">
                                <FontAwesomeIcon
                                  icon={['far', 'check']}
                                  className="ml-2 align-middle"
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        showQualifyMissingData && (
                          <DropdownMenu
                            mainClass="d-inline-block qualify-missing-data-menu"
                            type={isBrowserView() ? 'dropright' : ''}
                            triggerElement={(
                              <NewTooltip
                                content={t('inclusion:qualify-missing-data.declare-missing')}
                              >
                                <button className="btn btn-link text-gray-dark dotted-underline p-0">
                                  <span className="small font-italic">
                                    {t('inclusion:qualify-missing-data.declare-na-uk')}
                                  </span>
                                </button>
                              </NewTooltip>
                            )}
                          >
                            <QualifyMissingData
                              element={element}
                              inclusion={inclusion}
                              projectElement={projectElement}
                              projectElementId={projectElement.id}
                              moduleInstanceId={moduleInstanceId}
                              isDisabled={disableQualifyMissingData}
                              isEntryMode={isEntryMode}
                              showLoader={this.showLoader}
                              checkForCorruptedMissingDataCount={
                                this.checkForCorruptedMissingDataCount
                              }
                            />
                          </DropdownMenu>
                        )
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            {
              isEditMode && (
                <div className="col-auto pl-0 pr-3 element-drag-icon">
                  <NewTooltip
                    content={t('project:form.change-position')}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'grip-vertical']}
                      transform="grow-3"
                    />
                  </NewTooltip>
                </div>
              )
            }
          </div>
        </div>
      </div>
    ) : null;
  }
}


export default ElementBase;
