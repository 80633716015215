import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ElementBase from './ElementBase';
import BrowserView from './MixedView';
import MobileView from './MobileView';

const mapStateToProps = (state, ownProps) => ({
  projectElement: ownProps.projectElementId
    ? state.projectElements[ownProps.projectElementId] : null,
});

@connect(mapStateToProps, null, null, { forwardRef: true })
class ElementDivider extends Component {
  static propTypes = {
    parent: PropTypes.shape(),
    elementId: PropTypes.number.isRequired,
    projectElementId: PropTypes.number,
    projectElement: PropTypes.shape(),
    moduleInstanceId: PropTypes.number,
    methods: PropTypes.shape({
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
  };

  static defaultProps = {
    parent: null,
    projectElementId: null,
    projectElement: null,
    moduleInstanceId: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
  };

  checkLinks = () => {
    if (this.elBaseRef) {
      this.elBaseRef.checkLinks();
    }
  };

  render() {
    return (
      <ElementBase
        {...this.props}
        elementCategory="static"
        subClassName="py-0 divider-sub-card"
        centerContent
        noComment
        noLabel
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <BrowserView>
          <div className="col-3">
            <hr className="border-light-dark" />
          </div>
        </BrowserView>
        <MobileView>
          <div className="col-8">
            <hr className="border-light-dark" />
          </div>
        </MobileView>
      </ElementBase>
    );
  }
}


export default ElementDivider;
