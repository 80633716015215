import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { elementsActions } from '../redux/actions';
import ElementBase from './ElementBase';
import ElementComment from './ElementComment';
import ElementContentEditable from './ElementContentEditable';
import ErrorUtil from '../utils/ErrorUtil';
import ElementUtil from '../utils/ElementUtil';
import TextUtil from '../utils/TextUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { ELEMENT_TYPE_TITLE } from '../constants';

const TITLE_TAGS = {
  1: 'h3',
  2: 'h4',
};


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  projectElement: ownProps.projectElementId ? state.projectElements[ownProps.projectElementId]
    : null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (id, data) => dispatch(elementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class ElementTitle extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    patchElement: PropTypes.func.isRequired,
    projectElement: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
    isModule: false,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  updateElementText = (event) => {
    const name = event.target.value;
    this.timeoutHandler.doAfterTimeout(async () => {
      try {
        await this.props.patchElement(this.props.elementId, { name }, {
          admin: this.props.admin,
        });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    });
  };

  getTitleSiblingLevel = (currentLevel) => {
    switch (currentLevel) {
      case 1:
        return 2;
      default:
        return 1;
    }
  };

  render() {
    const {
      element, t, isEditMode,
    } = this.props;
    const { level } = element;

    const TitleTag = level ? TITLE_TAGS[level] : TITLE_TAGS[1];

    const siblingLevel = this.getTitleSiblingLevel(level ? parseInt(level, 10) : undefined);

    return (
      <ElementBase
        {...this.props}
        elementCategory="editable"
        subClassName={`element-title-sub-card level-${level || 1}`}
        siblingType={{
          key: ELEMENT_TYPE_TITLE,
          name: `project:form.switch-to-type.title-level-${siblingLevel.toString()}`,
          data: { level: siblingLevel },
        }}
        noLabel
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="col">
          <TitleTag className="card-title mb-0">
            {
              isEditMode ? (
                <ElementContentEditable
                  containerId={`element-${element.id}`}
                  defaultValue={element.name}
                  className="element-edit-input mr-3"
                  onChange={this.updateElementText}
                  placeholder={ElementUtil.getElementPlaceholder(element, t)}
                  autoTooltip
                  resizeInput
                  staticLabelClassName="resizing-title-span mr-3"
                  ref={(ref) => {
                    if (this.elBaseRef) {
                      this.elBaseRef.contentEditable = ref;
                    }
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: TextUtil.escape(ElementUtil.formatElementName(element, t)),
                  }}
                  className="mr-3"
                />
              )
            }
            {
              element.comment && (
                <ElementComment
                  comment={element.comment}
                  className="p-0"
                />
              )
            }
          </TitleTag>
        </div>
      </ElementBase>
    );
  }
}


export default ElementTitle;
